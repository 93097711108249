import React, { useState, useEffect, forwardRef, useImperativeHandle } from "react";
import { Select  } from "antd";

const { Option } = Select;

export default forwardRef((props, ref) => {
    const [classification, setClassification] = useState(props.value ? props.value : "work"); // create Date from cell value

    // sets value and stops editing
    useEffect(() => {
        setClassification(classification);
        return () => props.api.stopEditing()
    }, [classification, props.api])

    useImperativeHandle(ref, () => {
        return {
            getValue: () =>  classification,
            isPopup: () => true
        };
    });

    return (
        <Select style={{ width: "150px", height: "25px" }} defaultValue={classification} onChange={setClassification}>
            <Option value="work">Work</Option>
            <Option value="travel">Travel</Option>
        </Select>
    );
})
