import React, { createRef } from "react";
import "./App.scss";
import TimeRecords from "./components/timerecords/TimeRecords.js";
import Reports from "./components/timerecords/Reports.js";
import LoginPage from "./components/LoginPage.js";
import APIDoc from "./components/APIDoc";
import BEProxy from "./components/BEProxy";
import {PrivateRoute} from "./components/PrivateRoute.js";
import Keycloak from 'keycloak-js';
import { ReactKeycloakProvider } from '@react-keycloak/web';
import { Route, Switch, BrowserRouter, Link, Redirect } from 'react-router-dom';
import env from "@beam-australia/react-env";
import setAGGridLicenseKey from "./aggridLicense";
import TopMenu from "./components/TopMenu";

// ENV-variables
const baseUrl = env("BACKEND_URL")
    ? env("BACKEND_URL")
    : null;
const debug = env("DEBUG")
    ? env("DEBUG")
    : false;

const keycloak = new Keycloak({
    "realm": env("KEYCLOAK_REALM"),
    "url": env("KEYCLOAK_URL"),
    "ssl-required": "none",
    "clientId": env("KEYCLOAK_CLIENT_ID"),
    "public-client": true,
    "verify-token-audience": true,
    "use-resource-role-mappings": true,
    "confidential-port": 0,
    "enable-cors": true
    })
    
const keycloakProviderInitOptions = {
    // workaround, disabling of checking iframe cookie, because its a cross-site one, and chrome stopped accepting them
    // when they don't have SameSite=None set... and keycloak doesn't send a proper cookie yet: 
    // https://issues.redhat.com/browse/KEYCLOAK-12125
    "checkLoginIframe": false,
    onLoad: 'check-sso',
    // promiseType: 'native'
    }

function App() {

    setAGGridLicenseKey();

    const appRef = createRef();
    const tokenSetter = (token) => {
        localStorage.setItem('token', token.token);
    }

    if (!baseUrl) {
        return (
            <ReactKeycloakProvider authClient={keycloak} initOptions={keycloakProviderInitOptions} 
            onTokens={tokenSetter} LoadingComponent={<>Loading...</>}>
                <div className="App">
                    <div style={{ padding: "20px" }}>
                        <h3>Error</h3>
                        <p>No ENV-VAR 'REACT_APP_BACKEND_URL' provided</p>
                    </div>
                </div>
            </ReactKeycloakProvider>
        );
    } else {
        // remove trailing slash if exists
        // NOTE mcsuk: is this really necessary @bkurz?
        const fixedBaseUrl = baseUrl.substr(baseUrl.length - 1) === "/" ? baseUrl.slice(0, -1) : baseUrl;

        return (
            <ReactKeycloakProvider authClient={keycloak} initOptions={keycloakProviderInitOptions} onTokens={tokenSetter} LoadingComponent={<>Loading...</>}>
                {/* <KeycloakTokenSetter /> */}

                <div className="App" ref={appRef}>
                    <BrowserRouter forceRefresh={false}>
                        <nav style={{ borderBottom: "solid 1px #e8e8e8", overflow: "hidden", boxShadow: "0 0 30px #f3f1f1", backgroundColor: "white" }}>
                            <div style={{ width: "250px", float: "left" }}>
                                <Link to="/" style={{ display: "flex", backgroundColor: "white", textDecoration: "none" }}>
                                    <img src={process.env.PUBLIC_URL + "/mhx-logo.png"} alt="logo" style={{ height: "38px", margin: "4px 8px" }} />
                                    <h1 id="logo" style={{ margin: "auto", marginLeft: 0 }} className="App-logo">TimeControl</h1>
                                </Link>   
                            </div>
                            <div style={{ width: "calc(100% - 250px)", float: "left" }}>
                                <Route
                                    render={({ location, }) => {
                                        const locPath = location.pathname.split("/");
                                        const locPathLast = locPath[locPath.length-1];
                                        return  (
                                            <TopMenu location={location} locPathLast={locPathLast} />
                                        )
                                    }}
                                />
                            </div>
                        </nav>

                        <div style={{ height: "calc(100vh - 58px)", padding: "8px 8px 2px 8px", overflow: "auto" }}>
                            <Switch>
                                <Redirect from="/:url*(/+)" to={"/:url"} /> {/* Removes trailing slashes */}
                                <Route path="/login">
                                    <LoginPage />
                                </Route>
                                <PrivateRoute path="/doc-api">
                                    <APIDoc baseUrl={fixedBaseUrl} />
                                </PrivateRoute>
                                <PrivateRoute path="/be-proxy/:proxied*">
                                    <BEProxy baseUrl={fixedBaseUrl} />
                                </PrivateRoute>
                                <PrivateRoute path="/time-records">
                                    <TimeRecords
                                        appRef={appRef}
                                        baseUrl={fixedBaseUrl}
                                        debugLog={debugLog}
                                        enableGridEditing={true}
                                    />
                                </PrivateRoute>
                                <PrivateRoute path="/reports">
                                    <Reports
                                        baseUrl={fixedBaseUrl}
                                        debugLog={debugLog}
                                    />
                                </PrivateRoute>
                                <PrivateRoute path= "/">
                                    <Redirect to="time-records"/>
                                </PrivateRoute>
                            </Switch>
                        </div>
                    </BrowserRouter>
                </div>
            </ReactKeycloakProvider>
        );
    }
}

// debug mode output
const debugLog = (error, msg) => {
    // if not set -> not true
    if (debug) {
        // error
        if (error) console.error(msg.name + ": " + msg.message);
        // no error -> info
        else console.log(msg);
    }
};

export default App;
