
export default function DateAndTimeParser() {
    const minuteStep = 15; // Minute Step for time-cols - must be a factor of 60!
    
    // Terms:
    // Time: HH:mm
    // Date: dd.mm.yyyy
    // DateObj: valid JS-Date-Object

    // ########## TIME ##########
    
    const parseTimeToDateObj = (value) => {
        let date = new Date();

        if (typeof value === "string") {
            var match = value.match(/^([0-1]{0,1}[0-9]|[2][0-3])[:]{0,1}([0-5][0-9])$/);

            if ( match ) {
                const hours = match[1];
                const mins = match[2];
                date.setHours(hours, mins, 0, 0);
                return roundDateTimeToMinuteStep(date).toISOString();
            }
        }

        // value is parseable to valid Date, take it
        if (!isNaN(Date.parse(value))) {
            date = new Date(value);
        }

        return roundDateTimeToMinuteStep(date).toISOString();
    }

    const parseDateObjToTime = (value) => {
        const date = new Date(value).toLocaleTimeString([], { hour: "2-digit", minute: "2-digit" });;
        return date;
    }

    // ########## DATE ##########

    const parseDateToDateObj = (value) => {
        let date = new Date();

        // value is in form dd.mm.yyyy
        if ( /^\s*(3[01]|[12][0-9]|0?[1-9])\.(1[012]|0?[1-9])\.((?:19|20)\d{2})\s*$/.test(value) ) {
            const dd = value.split(".")[0];
            const mm = value.split(".")[1];
            const yyyy = value.split(".")[2];
            date = new Date(yyyy, mm - 1, dd);
        }
        // value is in form mm/dd/yyyy
        else if ( /^(0[1-9]|1[012])[- /.] (0[1-9]|[12][0-9]|3[01])[- /.] (19|20)\d\d$/.test(value) ) {
            const mm = value.split("/")[0];
            const dd = value.split("/")[1];
            const yyyy = value.split("/")[2];
            date = new Date(yyyy, mm - 1, dd);
        }
        // value is a valid Date
        else if (!isNaN(Date.parse(value))) {
            date = new Date(value);
        }
        // value is invalid -> return 'date'

        return setDateTimeToNoon(date).toISOString();
    }

    const parseDateObjToDate = (value) => {
        const date = new Date(value).toLocaleDateString();
        return date;
    }

    // ########## HELPERS ##########

    // round time of date to defined minuteStep
    const roundDateTimeToMinuteStep = (d) => {
        const date = new Date(d);

        let hours = date.getHours();
        const minutes = date.getMinutes();
        const roundedMins =
            Math.round(minutes / minuteStep) * minuteStep;
        if (roundedMins >= 60) hours++;
        const minutesRounded = roundedMins % 60;
        date.setHours(hours, minutesRounded, 0, 0);
        
        return date;
    };

    // sets time of date to noon
    const setDateTimeToNoon = (d) => {
        const date = new Date(d);
        date.setHours(12, 0, 0, 0);
        return date;
    };

    // sets date to given date, but keeps the time
    const setDateOfDateObj = (d, dateToTake) => {
        const date = new Date(d);
        const today = new Date(dateToTake);
        today.setHours(date.getHours(), date.getMinutes(), 0, 0);
        return today;
    };

    // adds hours to given date
    const addHoursToDate = (d, hours) => {
        const date = new Date(d);
        date.setTime(date.getTime() + hours * 60 * 60 * 1000);
        return date;
    };

    // get first day of month with given delta (e.g. delta = -1 --> last month)
    const getFirstDayOfRelativeMonth = (delta) => {
        const today = new Date();
        today.setMonth(today.getMonth()+delta);
        return today.getFullYear() + "-" + ("0"+(today.getMonth()+1)).slice(-2) + "-01 00:00:00"; 
    }

    // get last day of month with given delta (e.g. delta = -1 --> last month)
    const getLastDayOfRelativeMonth = (delta) => {
        const today = new Date();
        today.setMonth(today.getMonth()+delta);
        const lastDayOfMonth = new Date(today.getFullYear(), today.getMonth()+1, 0);
        return today.getFullYear() + "-" + ("0"+(today.getMonth()+1)).slice(-2) + "-" + lastDayOfMonth.getDate() + " 00:00:00";
    }

    return {
        minuteStep,
        // ########## TIME ##########
        parseTimeToDateObj,
        parseDateObjToTime,
        // ########## DATE ##########
        parseDateToDateObj,
        parseDateObjToDate,
        // ########## HELPERS ##########    
        roundDateTimeToMinuteStep,
        setDateTimeToNoon,
        setDateOfDateObj,
        addHoursToDate,
        getFirstDayOfRelativeMonth,
        getLastDayOfRelativeMonth
    };
}