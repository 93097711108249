import React, { useCallback } from 'react'
import { Redirect, withRouter } from 'react-router-dom'
import { useKeycloak } from '@react-keycloak/web'
import env from "@beam-australia/react-env";

const LoginPage = withRouter(props => {
    var { location } = props;
    var { keycloak } = useKeycloak();

    const login = useCallback(() => {
      keycloak.login()
    }, [keycloak])

    const urlSearchParams = new URLSearchParams(window.location.search);
    // get value from .env-file
    let directIDP = env('DIRECTIDP') || 'true';
    // override value with URL-Parameter
    if(urlSearchParams.get('directidp') !== null) {
        directIDP = urlSearchParams.get('directidp');
    }

    if(directIDP === 'true' && !keycloak.authenticated) {
        login();
    }

    const { from } = location.state || { from: { pathname: '/' } }
    if (keycloak.authenticated) {
      return <Redirect to={from} />
    }

    return (
      <div style={{ display: 'flex', justifyContent: 'center', height: '100%', flexBasis: '100px' }}>
        <button type="button" onClick={login} style={{alignSelf: 'center', flexBasis: '200px', minHeight: '50px'}}>
          Login
        </button>
      </div>
    )
})

export default LoginPage
