// CRUD operations

// TODO: improve!! returning false, when successful etc. is extremely ugly!

export default function CRUDController(debugLog) {
    // CREATE
    // POST
    // returns false, when successful / error-message, when not successful
    const create = async (url, data, authToken) => {
        const answer = await fetch(url, {
            method: "POST",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                "Authorization": `Bearer ${authToken}`
            },
            body: JSON.stringify(data)
        })
            .then(async response => {
                if (response.ok) {
                    return response.json(); // JSON parse
                } else {
                    // 400 - Bad Request
                    if (response.status === 400) {
                        const responseJson = await response.json();
                        const errorMessage = responseJson.errorMessage;
                        throw new Error( response.status + ": " + response.statusText + ": " + errorMessage );
                    } else
                        throw new Error( response.status + ": " + response.statusText );
                }
            })
            .then(responseJson => {
                debugLog( false, "CRUD - create (url: " + url + ") successfully" );
                return JSON.parse(JSON.stringify(responseJson));
            })
            .catch(error => {
                throw new Error( "CRUD - create (url: " + url + ") -> " + error.message );
            });
        return answer;
    };

    // READ
    // GET
    const read = async (url, authToken) => {
        const answer = await fetch(url, {
            method: "GET",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                "Authorization": `Bearer ${authToken}`
            }
        })
            .then(async response => {
                if (response.ok) {
                    return response.json(); // JSON parse
                } else {
                    // 400 - Bad Request
                    if (response.status === 400) {
                        const responseJson = await response.json();
                        const errorMessage = responseJson.errorMessage;
                        throw new Error( response.status + ": " + response.statusText + ": " + errorMessage );
                    } else
                        throw new Error( response.status + ": " + response.statusText );
                }
            })
            .then(responseJson => {
                debugLog( false, "CRUD - read (url: " + url + ") successfully" );
                return JSON.parse(JSON.stringify(responseJson));
            })
            .catch(error => {
                throw new Error( "CRUD - create (url: " + url + ") -> " + error.message );
            });
        return answer;
    };

    // UPDATE
    // PUT
    // returns false, when successful / error-message, when not successful
    const update = async (url, id, data, authToken) => {
        const answer = await fetch(url + "/" + id, {
            method: "PUT",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                "Authorization": `Bearer ${authToken}`
            },
            body: JSON.stringify(data)
        })
            .then(async response => {
                if (response.ok) {
                    return response.json(); // JSON parse
                } else {
                    // 400 - Bad Request
                    if (response.status === 400) {
                        const responseJson = await response.json();
                        const errorMessage = responseJson.errorMessage;
                        throw new Error( response.status + ": " + response.statusText + ": " + errorMessage );
                    } else
                        throw new Error( response.status + ": " + response.statusText );
                }
            })
            .then(responseJson => {
                debugLog( false, "CRUD - update (url: " + url + ") successfully" );
                return JSON.parse(JSON.stringify(responseJson));
            })
            .catch(error => {
                throw new Error( "CRUD - update (url: " + url + ") -> " + error.message );
            });
        return answer;
    };

    // DELETE
    // DELETE
    // returns false, when successful / error-message, when not successful
    const remove = async (url, id, authToken) => {
        const answer = await fetch(url + "/" + id, {
            method: "DELETE",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                "Authorization": `Bearer ${authToken}`
            }
        })
            .then(async response => {
                if (response.ok) {
                    return response.json(); // JSON parse
                } else {
                    // 400 - Bad Request
                    if (response.status === 400) {
                        const responseJson = await response.json();
                        const errorMessage = responseJson.errorMessage;
                        throw new Error( response.status + ": " + response.statusText + ": " + errorMessage );
                    } else
                        throw new Error( response.status + ": " + response.statusText );
                }
            })
            .then(responseJson => {
                debugLog(
                    false,
                    "CRUD - delete (url: " + url + ") successfully"
                );
                return JSON.parse(JSON.stringify(responseJson));
            })
            .catch(error => {
                throw new Error( "CRUD - delete (url: " + url + ") -> " + error.message );
            });
        return answer;
    };

    return { create, read, update, remove };
}
