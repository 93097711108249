import React, {useEffect, useState} from "react";
import "./TimeRecordsButtonToolbar.scss";
import { Input, Button, Radio, Checkbox, Badge } from "antd";
import _ from "lodash";

export default function TimeRecordsButtonToolbar(props) {

    const {
        filterMonthRelative, setFilterMonthRelative, 
        filterProductiveOnly, setFilterProductiveOnly, 
        filterGlobal, setFilterGlobal,
        ...rest
    } = props;

    const [sortState, setSortState] = useState(null);

    useEffect(() => {
        if(rest.columnState !== null) {
            let sortState = rest.columnState.filter((s) => {
                return s.sort != null;
            })
            .map((s) => {
                return {colId: s.colId, sort: s.sort};
            })
            setSortState(sortState);
        }
    },[rest.columnState]);

    return (
        <div className="button-toolbar">

            {/* ######################################## FIRST ROW ######################################## */}
            <div className="button-toolbar-row" style={{ display: "flex", justifyContent: "space-between", marginBottom: "8px" }}>

                {/* ######################################## Add/copy/select/delete Rows ######################################## */}
                <div style={{ display: "flex" }}>
                    <Button type="text" style={{ cursor: "default" }}>New rows:</Button>
                    <Button value={1} onClick={rest.newRows} disabled={rest.disableAllButtons}>1</Button>
                    <Button value={5} onClick={rest.newRows} disabled={rest.disableAllButtons}>5</Button>
                    <Button value={10} onClick={rest.newRows} disabled={rest.disableAllButtons}>10</Button>
                    <Button style={{ marginLeft: "8px" }} onClick={rest.copyRows} disabled={rest.disableAllButtons}>Copy selected rows</Button>
                    <Button onClick={rest.selectAll} disabled={rest.disableAllButtons}>Select all</Button>
                    <Button style={{ marginLeft: "8px", marginRight: "8px" }} onClick={rest.markRowAsDeleted} disabled={rest.disableAllButtons}>Delete row</Button>
                </div>

                {/* ######################################## Save/Refresh ######################################## */}
                <div style={{ display: "flex" }}>
                    <Button onClick={() => props.save()} disabled={props.disableAllButtons || !props.gridDirty}>Save</Button>
                    <Button onClick={() => props.refresh()} disabled={props.disableAllButtons}>Refresh</Button>
                </div>

            </div>

            {/* ######################################## SECOND ROW ######################################## */}
            <div className="button-toolbar-row" style={{ display: "flex", justifyContent: "space-between", marginBottom: "8px" }}>

                {/* ######################################## Global Search ######################################## */}
                <div style={{ display: "flex", alignItems: "center", minWidth: "143px", width: "230px" }}>
                    <Input size="small" style={{ marginRight: "8px" }} placeholder="Search..." allowClear value={filterGlobal} onChange={(e) => setFilterGlobal(e.target.value)} disabled={rest.disableAllButtons}/>
                </div>

                {/* ######################################## Filter ######################################## */}
                <div style={{ display: "flex", alignItems: "center" }}>
                    <Button type="text" style={{ cursor: "default" }}>
                        <Badge dot status={_.includes(getActiveFilterValues(rest.filterModel), "custom") ? "warning" : null} count={_.includes(getActiveFilterValues(rest.filterModel), "custom") ? "custom" : 0} >Filters:</Badge>
                    </Button>
                    <Radio.Group size="small" value={
                        filterMonthRelative === 0 ? "thismonth" :
                            filterMonthRelative === -1 ? "lastmonth" :
                                "alltime"
                    }>
                        <Radio.Button value="lastmonth" onClick={() => setFilterMonthRelative(-1)} disabled={rest.disableAllButtons}>Last Month</Radio.Button>
                        <Radio.Button value="thismonth" onClick={() => setFilterMonthRelative(0)} disabled={rest.disableAllButtons}>This Month</Radio.Button>
                        <Radio.Button value="alltime" onClick={() => setFilterMonthRelative(null)} disabled={rest.disableAllButtons}>All time</Radio.Button>
                    </Radio.Group>
                    <Checkbox size="small" checked={filterProductiveOnly} style={{ marginLeft: "8px" }} onChange={(e) => setFilterProductiveOnly(e.target.checked)} disabled={rest.disableAllButtons}>Only Productive</Checkbox>
                    <Button size="small" value="reset" onClick={rest.resetAllFilters} disabled={rest.disableAllButtons}>Reset</Button>
                </div>

                {/* ######################################## Hours ######################################## */}
                <div style={{ display: "flex", alignItems: "center" }}>
                    { rest.rows } rows / { rest.hours.toFixed(2) } hours
                </div>

                {/* ######################################## Sort ######################################## */}
                <div style={{ display: "flex", alignItems: "center" }}>
                    <Button type="text" style={{ cursor: "default" }}>
                        <Badge dot status={getActiveSortValue(rest.sortModel) === "custom" ? "warning" : null} count={getActiveSortValue(rest.sortModel) === "custom" ? "custom" : 0} >Sorting:</Badge>
                    </Button>
                    <Radio.Group size="small" value={getActiveSortValue(sortState)}>
                        <Radio.Button value="dateandtime" onClick={rest.sortDateAndTime} disabled={rest.disableAllButtons}>Date & Time</Radio.Button>
                        <Radio.Button value="openproject_id" onClick={rest.sortOpenProjectID} disabled={rest.disableAllButtons}>OpenProject ID</Radio.Button>
                        <Radio.Button value="none" onClick={rest.resetAllSortings} disabled={rest.disableAllButtons}>None</Radio.Button>
                    </Radio.Group>
                </div>

                {/* ######################################## Column State ######################################## */}
                <div style={{ display: "flex", alignItems: "center" }}>
                    <Button type="text" style={{ cursor: "default" }}>Columns:</Button>
                    <Button size="small" onClick={props.autoSizeAllColumns} disabled={props.disableAllButtons}>Fit</Button>
                    <Button size="small" style={{ marginLeft: "2px" }} onClick={props.resetColumnState} disabled={props.disableAllButtons}>Reset</Button>
                </div>


                {/* ######################################## Export ######################################## */}
                <div style={{ display: "flex", alignItems: "center" }}>
                    <Button type="text" style={{ cursor: "default" }}>Export:</Button>
                    <Button size="small" style={{ marginRight: "8px" }} onClick={rest.excelExport} disabled={rest.disableAllButtons}>Excel</Button>
                </div>
            </div>

        </div>
    );
}

// returns string-array with all active filterValues
// special case: "allTime" is always included, if no 'date'-filters are active at all
// "custom" may be included multiple times
function getActiveFilterValues(filterModel) {
    let activeFilterValues = [];

    // DATE
    var today = new Date();
    // this month attr
    const lastDayOfThisMonth = new Date(today.getFullYear(), today.getMonth()+1, 0);
    var firstDayThisMonth = today.getFullYear() + "-" + ("0"+(today.getMonth()+1)).slice(-2) + "-01 00:00:00"; 
    var lastDayThisMonth = today.getFullYear() + "-" + ("0"+(today.getMonth()+1)).slice(-2) + "-" + lastDayOfThisMonth.getDate() + " 00:00:00";
    const filterThisMonth = {dateFrom: firstDayThisMonth, dateTo: lastDayThisMonth, type: "inRange", filterType: "date"};
    // last month attr
    const lastDayOfLastMonth = new Date(today.getFullYear(), today.getMonth(), 0);
    var firstDayLastMonth = today.getFullYear() + "-" + ("0"+(today.getMonth())).slice(-2) + "-01 00:00:00"; 
    var lastDayLastMonth = today.getFullYear() + "-" + ("0"+(today.getMonth())).slice(-2) + "-" + lastDayOfLastMonth.getDate() + " 00:00:00";
    const filterLastMonth = {dateFrom: firstDayLastMonth, dateTo: lastDayLastMonth, type: "inRange", filterType: "date"};
    // this month
    if(_.isEqual(filterThisMonth, filterModel?.date)) activeFilterValues.push("thismonth");
    // last month
    else if(_.isEqual(filterLastMonth, filterModel?.date)) activeFilterValues.push("lastmonth");
    // all time
    else if (!filterModel || !_.includes(Object.keys(filterModel), "date")) activeFilterValues.push("alltime");
    // other 'date'-filter
    else activeFilterValues.push("custom");

    // PRODUCTIVE
    // true
    if (
        _.isEqual(filterModel?.productive, {filterType: "text", type: "equals", filter: "true"}) ||
        _.isEqual(filterModel?.productive, {filterType: "text", type: "contains", filter: "true"}) ||
        _.isEqual(filterModel?.productive, {filterType: "text", type: "startsWith", filter: "true"})
    ) activeFilterValues.push("onlyproductive");
    // other 'productive'-filter
    else if (filterModel && _.includes(Object.keys(filterModel), "productive")) activeFilterValues.push("custom");

    // ALL OTHERS
    // any other filters
    if (filterModel && _.size(_.difference(Object.keys(filterModel), ["date", "productive"]))) activeFilterValues.push("custom");

    return activeFilterValues;
}

function getActiveSortValue(sortState) {
    if(sortState) {
        if(_.isEqual(sortState, [{"colId":"date","sort":"asc"},{"colId":"from","sort":"asc"}])) {
            return "dateandtime";
        }
        else if(_.isEqual(sortState, [{"colId":"openproject_id","sort":"asc"}])) {
            return "openproject_id";
        }
        else if (sortState.length > 0) {
            return "custom";
        }
        else {
            return "none";
        }
    } else {
        return "none";
    }

}
