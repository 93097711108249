import _ from "lodash";

export default function OpenprojectWorkPackageSelectorRenderer(props) {
    const value = props.value?.toString() ?? ""; // NOTE: we work with strings for openproject workpackage keys/ids throughout the codebase
    const entries = props.entries;

    const chosenEntry = _.find(entries, (e) => e.id === value);
    if (chosenEntry) {
        return chosenEntry.name;
    } else {
        return `${value}`; // unknown Openproject workpackage ID
    }
}
