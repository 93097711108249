import React from "react";
import { Checkbox } from "antd";

export default function BoolRenderer(props) {
    const gridApi = props.api;
    const defaultChecked = props.value

    // sets focused cell
    function setCellValue(value) {
        var focusedCell = gridApi.getFocusedCell();
        if (focusedCell) {
            var rowNode = gridApi.getDisplayedRowAtIndex(focusedCell.rowIndex);
            if (rowNode) rowNode.setDataValue(focusedCell.column.colId, value);
        }
    };

    return <Checkbox defaultChecked={defaultChecked} onChange={(e) => setCellValue(e.target.checked)} disabled={props.disabled}/>;
}
