import React, { useState, useEffect, useRef, forwardRef, useImperativeHandle, useCallback } from "react";
import { AutoComplete  } from "antd";

const { Option } = AutoComplete;

export default forwardRef((props, ref) => {

    // openproject workpackage entries are passed through cellEditorParams
    const entries = props.entries;

    // NOTE: ant select seems to only with string-based option keys, not numbers... so we work with openproject-IDs as strings inside of this component
    // see https://github.com/ant-design/ant-design/issues/11155

    const [selectedWorkPackage, setSelectedWorkPackage] = useState(props.value ? props.value.toString() : ""); 

    // NOTE: we use an indirection with the editing state here because 
    // props.api.stopEditing() immediately stops the editing and we wouldn't have a chance to actually set a new value
    const [editing, setEditing] = useState(true);
    useEffect(() => {
        if (!editing) {
            props.api.stopEditing();
        }
    }, [editing, props.api]);

    const [isOpen, setIsOpen] = useState(false);

    const refSelect = useRef(null);
    
    // focus and open on editor start
    useEffect(() => {
        setIsOpen(true);

        // HACK: timeout hack is used because there does not seem to be a good (reactive) point in time when to focus
        // on the input that works properly
        setTimeout(() => { refSelect.current.focus(); }, 0);
    }, [refSelect]);
    

    const onSelect = useCallback((value, option) => {
        setSelectedWorkPackage(value);
        setEditing(false);
    }, []);

    const onSearch = useCallback((value) => {
        setSelectedWorkPackage(value);
    }, []);

    useImperativeHandle(ref, () => {
        return {
            getValue: () => {
                // ant design always works with strings, but when passing the final value outside, we (try to) convert it to an integer
                const number = parseInt(selectedWorkPackage, 10);
                if (isNaN(number))
                    return selectedWorkPackage;
                else
                    return number;
            },
            isPopup: () => true
        };
    });

    const onKeyDown = (event) => {
        const keyCode = event.keyCode;

        const KEY_LEFT = 37;
        const KEY_UP = 38;
        const KEY_RIGHT = 39;
        const KEY_DOWN = 40;
        const KEY_PAGE_UP = 33;
        const KEY_PAGE_DOWN = 34;
        const KEY_PAGE_HOME = 36;
        const KEY_PAGE_END = 35;
  
         const isNavigationKey = keyCode === KEY_LEFT ||
            keyCode === KEY_RIGHT ||
            keyCode === KEY_UP ||
            keyCode === KEY_DOWN ||
            keyCode === KEY_PAGE_DOWN ||
            keyCode === KEY_PAGE_UP ||
            keyCode === KEY_PAGE_HOME ||
            keyCode === KEY_PAGE_END;
  
            if (isNavigationKey) {
                // this stops the grid from receiving the event and executing keyboard navigation
                event.stopPropagation();
            }
     }

    const inputWidth = props.column.actualWidth;
    const dropdownWidth = Math.max(400, props.column.actualWidth);

    const options = entries.map(d => <Option key={d.id} label={d.name}>{d.name}</Option>);
    return <AutoComplete onKeyDown={event => onKeyDown(event)}
        autoFocus={true}
        ref={refSelect} style={{ width: inputWidth }} value={selectedWorkPackage} onSearch={onSearch} onSelect={onSelect} 
        open={isOpen}
        filterOption={(inputValue, option) => { 
            const value = option.label;
            return value.toUpperCase().includes(inputValue.toUpperCase()); 
        }}
        dropdownMatchSelectWidth={dropdownWidth}
        defaultActiveFirstOption={false}>
            {options}
        </AutoComplete>;
})
