import {memo, forwardRef, useState, useRef, useEffect, useImperativeHandle} from 'react';

export const TimePickerEditor = memo(
    forwardRef((props, ref) => {
        const [value, setValue] = useState(props.value);
        const refInput = useRef(null);

        useEffect(() => {
            refInput.current.focus();
        },[]);

        useImperativeHandle(ref, () => {
            return {
                getValue() {
                    return value;
                },
                isCancelBeforeStart() {
                    return false;
                },
                isCancelAfterEnd() {
                    return false;
                }
            }
        });

        return (
            <input
                type="time"
                ref={refInput}
                value={value}
                onChange={(event) => setValue(event.target.value)}
                onDoubleClick={(event) => props.api.stopEditing()}
                step="900"
            />
        )
    })
)