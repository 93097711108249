import React, { useEffect, useState } from 'react';
import { useKeycloak } from '@react-keycloak/web'
import { Menu, Button } from 'antd'
import {Link} from "react-router-dom";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faFile, faInfoCircle, faTable, faTh} from "@fortawesome/free-solid-svg-icons";

function TopMenu(props) {
    const { keycloak, keycloakInitialized } = useKeycloak()
    const [ userProfile, setUserProfile ] = useState(undefined);

    useEffect(() => {
        if (keycloak.authenticated)
            keycloak.loadUserProfile().then(profile => {
                setUserProfile(profile);
            })
    }, [keycloak, keycloakInitialized])

    let items = [];
    if(keycloak.authenticated) {
        items = [
            {
                label: (
                    <Link to="/time-records"><FontAwesomeIcon icon={faTh} style={{marginRight: "0.5rem"}}/> Time Records</Link>
                ),
                key: 'time-records'
            },
            {
                label: (
                    <Link to="/reports"><FontAwesomeIcon icon={faTable} style={{marginRight: "0.5rem"}}/> Reports</Link>
                ),
                key: 'reports'
            },
            {
                label: (
                    <Link to="/doc-api/"><FontAwesomeIcon icon={faFile} style={{marginRight: "0.5rem"}}/> API</Link>
                ),
                key: 'doc-api'
            },
            {
                label: (
                    <Link to="/docs/" target="_blank" rel="noopener noreferrer"><FontAwesomeIcon icon={faInfoCircle}
                                                                                                 style={{marginRight: "0.5rem"}}/> Help</Link>
                ),
                key: 'docs'
            },
            {
                type: 'divider'
            }

        ];
    }
    let userItems = [];
    if(userProfile) {
        userItems = [
            {
                disabled: true,
                style: { cursor: "unset" },
                label: userProfile.firstName+' '+userProfile.lastName,
                key: 'userProfile'
            },
            {
                disabled: true,
                style: { cursor: "unset" },
                label: (<Button onClick={() => keycloak.logout()} >Logout</Button>),
                key: 'logout'
            }
        ];
        items = items.concat(userItems);
    }
    return (<Menu mode="horizontal" selectedKeys={props.locPathLast} defaultSelectedKeys={props.location.pathname.split("/")[1]} style={{ position: "relative", display: "flex", justifyContent: "flex-end", borderBottom: "none", backgroundColor: "white" }} items={items} />)
}
export default TopMenu;