import React from 'react'
import useFetch from 'use-http'
import {useParams} from "react-router-dom"

const BEProxy = function(props) {

  let { proxied } = useParams();

  const token = localStorage.getItem("token");
  const url = props.baseUrl + "/" + proxied;
  const { loading, error, data } = useFetch(url, {
    headers: {
      "Accept": "application/json,*/*",
      "Authorization": `Bearer ${token}`
    },
    responseType: 'blob'
  }, []);


  if (!data) {
    if (loading)
      return "Loading...";
    else
      return "Error: " + error;
  } else {
    var data_url = URL.createObjectURL(data);
    return <iframe title="beproxy-iframe" key="beproxy-iframe" src={data_url} style={{position:'fixed', top:0, left:0, bottom:0, right:0, width:'100%', height:'100%', border:'none', margin:0, padding:0, overflow:'hidden' }} />;
  }
}

export default BEProxy;