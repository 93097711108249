import React from 'react'
import SwaggerUI from "swagger-ui-react"
import "swagger-ui-react/swagger-ui.css"

const APIDoc = function(props) {

    const documentURL = props.baseUrl + "/_meta/openapi-spec.json";

    return <SwaggerUI url={documentURL} 
    requestInterceptor={(req) => {
        // we add the previously fetched authentication token to every request (both fetching of the documentURL and each try-it-out request)
        var token = localStorage.getItem("token");
        req.headers.Authorization = "Bearer " + token;
        return req;
      }} />;
}

export default APIDoc;