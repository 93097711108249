import React, { useRef, useState } from "react";
import "./TimeRecords.scss";
import {AgGridReact} from 'ag-grid-react';
import 'ag-grid-enterprise';
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-balham.css";
import { Alert } from "antd";
import BoolRenderer from "./cellrenderers/BoolRenderer.js";
import OpenprojectWorkPackageSelectorRenderer from "./cellrenderers/OpenprojectWorkPackageSelectorRenderer.js";
import CRUDController from "../../controllers/CRUDController.js";
import DateAndTimeParser from "../../utils/DateAndTimeParser";
import { Button } from "antd";


export default function Reports(props) {
    const [gridApi, setGridApi] = useState(null);
    const [gridColumnApi, setGridColumnApi] = useState(null);

    const columnDefs = initColumnDefs();
    const defaultColDef = initDefaultColDef();
    const [rowData, setRowData] = useState(null);
    const columnTypes = initColumnTypes();

    // const [loading, setLoading] = useState(true);   
    const [feedbackMsg, setFeedbackMsg] = useState("");
    const [feedbackIsError, setFeedbackIsError] = useState(false);

    // TODO: find better way to get rid of hack
    // NOTE/HACK: because the rest of the code unfortunately mixes reactive code and functions/closures in hard-to-reason-about ways,
    // we need to keep a reference to the openprojectWorkpackages, so that you can access the up-to-date workpackages data inside of the functions below 
    // (such as initColumnTypes)
    // using a regular useState() won't work, because of "stale-closures"
    // const [openprojectWorkpackages, setOpenprojectWorkpackages] = useState([]);
    const openprojectWorkpackagesRef = useRef([]);

    const _CRUDController = new CRUDController(props.debugLog);
    const dateAndTimeParser = new DateAndTimeParser();

    return (
        <div style={{ height: "100%" }}>
            <div className="button-toolbar">
                <div className="button-toolbar-row" style={{ display: "flex", justifyContent: "flex-end", marginBottom: "8px" }}>
                    <div style={{ display: "flex" }}>
                        <Button onClick={() => refresh()}>Refresh</Button>
                    </div>
                </div>
            </div>

        
            <div className="reports" style={{ width: "100%", display: "flex", flexDirection: "column", paddingTop: 0, height: "calc(100% - 55px)" }}>
                {feedbackMsg && (
                    <div style={{ paddingBottom: "8px" }}>
                        <Alert message={feedbackMsg} type={feedbackIsError? "error" : "success"}showIcon/>
                    </div>
                )}
                <div className="ag-theme-balham" style={{height: '100%'}}>
                    <AgGridReact
                        // grid options
                        onGridReady={onGridReady}
                        rowData={rowData}
                        columnDefs={columnDefs}
                        defaultColDef={defaultColDef}
                        columnTypes={columnTypes}
                        animateRows={true}
                        enableRangeSelection={true}
                        suppressMultiRangeSelection={true}
                        suppressRowClickSelection={true}
                        components={{
                            boolRenderer: BoolRenderer,
                            openprojectWorkPackageSelectorRenderer: OpenprojectWorkPackageSelectorRenderer,
                        }}
                        getRowId={function (data) {
                            return data.data.id;
                        }}
                        overlayLoadingTemplate={
                            '<span class="ag-overlay-loading-center">Loading...</span>'
                        }
                        overlayNoRowsTemplate={
                            '<span class="ag-overlay-loading-center">No data.</span>'
                        }
                        sideBar={true}
                        groupDisplayType="multipleColumns"
                        autoGroupColumnDef={{
                            //headerName: 'Group',
                            headerValueGetter: params => `${params.colDef.headerName} Group`,
                            minWidth: 120,
                            resizable: true,
                            cellRendererParams: {
                                suppressCount: true,
                            },
                            comparator: function (valueA, valueB, nodeA, nodeB, isInverted) {
                              var res = valueA === valueB ? 0 : valueA > valueB ? 1 : -1;
                              return res;
                            },
                            sort: 'asc',
                        }}
                        pivotRowTotals="after"
                        onRowDataUpdated={expandCurrentYearAndMonth}
                        onColumnRowGroupChanged={expandCurrentYearAndMonth}
                        onColumnPivotModeChanged={expandCurrentYearAndMonth}
                    />
                </div>
            </div>
        </div>
    );

    // ######################################## INIT FUNCTIONS ########################################

    // grid ready
    function onGridReady(params) {
        // set states
        setGridApi(params.api);
        setGridColumnApi(params.columnApi);
        // refresh data
        refresh(params);    // passing params to refresh() needed, because setting states may be too lazy and refresh() will need 'gridApi' and 'gridColumnApi'

        // default sorting
        var defaultSortModel = [
            { colId: 'Group', sort: 'asc', sortIndex: 0 },
            { colId: 'date', sort: 'asc', sortIndex: 1 },
          ];

        params.columnApi.applyColumnState({ state: defaultSortModel });

    }

    // Init columnDefs
    function initColumnDefs() {
        const dateComparator = (valueA, valueB, nodeA, nodeB, isInverted) => {
            var dateA = dateAndTimeParser.parseDateToDateObj(valueA);
            var dateB = dateAndTimeParser.parseDateToDateObj(valueB);
            if (dateA === dateB) return 0;
            return (dateA < dateB) ? -1 : 1;
        };
        const timeComparator = (valueA, valueB, nodeA, nodeB, isInverted) => {
            var dateA = dateAndTimeParser.parseTimeToDateObj(valueA);
            var dateB = dateAndTimeParser.parseTimeToDateObj(valueB);
            if (dateA === dateB) return 0;
            return (dateA < dateB) ? -1 : 1;
        };
        return [
            {
                headerName: "Id",
                field: "id",
                width: 92,
                hide: true,
            },
            {
                headerName: "Year",
                field: "from_year",
                width: 50,
                valueGetter: (params) => {
                    if ( ! params.data?.["from"] )
                        return null;

                    const fromObj = new Date(params.data?.["from"]);
                    return fromObj.getFullYear();
                },
                rowGroup: true,
                hide: true,
                enableRowGroup: true,
                enablePivot: true,
            },
            {
                headerName: "Month",
                field: "from_month",
                width: 50,
                valueGetter: (params) => {
                    if ( ! params.data?.["from"] )
                        return null;

                    const fromObj = new Date(params.data?.["from"]);
                    return fromObj.getMonth() + 1;
                },
                rowGroup: true,
                hide: true,
                enableRowGroup: true,
                enablePivot: true,
            },
            {
                headerName: "Week",
                field: "from_week",
                width: 50,
                valueGetter: (params) => {
                    if ( ! params.data?.["from"] )
                        return null;

                    const fromObj = new Date(params.data?.["from"]);
                    return "ISOWeek " + getISOWeek(fromObj);
                },
                rowGroup: true,
                hide: true,
                enableRowGroup: true,
                enablePivot: true,
            },
            {
                headerName: "Day of Week",
                field: "from_dow",
                width: 50,
                valueGetter: (params) => {
                    if ( ! params.data?.["from"] )
                        return null;

                    const fromObj = new Date(params.data?.["from"]);
                    return  getDayOfWeek(fromObj);
                },
                hide: true,
                enableRowGroup: true,
                enablePivot: true,
            },
            {
                headerName: "Date",
                field: "date",
                type: "date",
                comparator: dateComparator,
                width: 100,
            },
            {
                headerName: "From",
                field: "from",
                type: "time",
                comparator: timeComparator,
                width: 80,
            },
            {
                headerName: "To",
                field: "to",
                type: "time",
                comparator: timeComparator,
                width: 80,
            },
            {
                headerName: "Hours",
                field: "hours",
                width: 105,
                valueGetter: (params) => {
                    const to = dateAndTimeParser.setDateOfDateObj(params.data?.["to"], new Date());
                    const from = dateAndTimeParser.setDateOfDateObj(params.data?.["from"], new Date());
                    const factor = params.data?.["factor"];
                    const hours = (to - from)*factor / (60 * 60 * 1000);
                    return hours;
                },
                aggFunc: 'sum',
            },
            {
                headerName: "Activity",
                field: "activity",
                width: 400,
                enableRowGroup: true,
                valueGetter: (params) => { return params.data?.["activity"] ? params.data?.["activity"] : ""; },
                enablePivot: true,
            },
            {
                headerName: "OpenProject ID",
                field: "openproject_id",
                type: "openprojectID",
                width: 400
            },
            {
                headerName: "Location",
                field: "location",
                enableRowGroup: true,
                valueGetter: (params) => { return params.data?.["location"] ? params.data?.["location"] : ""; },
                enablePivot: true,
            },
            {
                headerName: "Pool",
                field: "pool",
                width: 200,
                rowGroup: true,
                enableRowGroup: true,
                valueGetter: (params) => { return params.data?.["pool"] ? params.data?.["pool"] : ""; },
                enablePivot: true,
            },
            {
                headerName: "Grp1",
                field: "grp1",
                width: 200,
                enableRowGroup: true,
                valueGetter: (params) => { return params.data?.["grp1"] ? params.data?.["grp1"] : ""; },
                enablePivot: true,
            },
            {
                headerName: "Line Classification",
                field: "classification",
                type: "classification",
                enableRowGroup: true,
                valueGetter: (params) => { return params.data?.["classification"] ? params.data?.["classification"] : ""; },
                enablePivot: true,
            },
            {
                headerName: "Factor",
                field: "factor",
                width: 75,
            },
            {
                headerName: "Productive",
                field: "productive",
                type: "bool",
                width: 100,
                enableRowGroup: true,
                valueGetter: (params) => { return params.data?.["productive"] ? params.data?.["productive"] : ""; },
                enablePivot: true,
            },
        ];
    }

    // Init defaultColDef
    function initDefaultColDef() {
        return {
            sortable: true,
            filter: true,
            editable: false,
            resizable: true,
            width: 150,
        };
    }

    // Init columnTypes
    function initColumnTypes() {
        return {
            date: {
                filter: "agDateColumnFilter",
                filterParams: {
                    // custom comparator for date comparison - ignores time in date object
                    comparator: (filterLocalDateAtMidnight, cellValue) => {
                        if (cellValue) {
                            const filterDate = new Date(dateAndTimeParser.parseDateToDateObj(filterLocalDateAtMidnight));
                            const cellDate = new Date(dateAndTimeParser.parseDateToDateObj(cellValue));

                            if (filterDate.getTime() === cellDate.getTime()) return 0;
                            else if (cellDate < filterDate) return -1;
                            else if (cellDate > filterDate) return 1;
                        }
                    },
                    filterOptions: [
                        "equals",
                        "inRange",
                        "lessThanOrEqual",
                        "greaterThanOrEqual",
                    ],
                    defaultOption: "greaterThanOrEqual",
                    inRangeInclusive: true,
                    closeOnApply: true,
                    minValidYear: 2000,
                },
                valueGetter: (params) => {
                    let value = params.data?.[params.colDef.field];
                    if (value) return dateAndTimeParser.parseDateObjToDate(value);
                    else return null;
                },
            },
            time: {
                valueGetter: (params) => {
                    let value = params.data?.[params.colDef.field];
                    if (value) return dateAndTimeParser.parseDateObjToTime(value);
                    else return null;
                },
            },
            bool: {
                cellRenderer: "boolRenderer",
                cellRendererParams: { disabled: true},
                valueParser: (params) => {
                    return params.newValue === "false" || !params.newValue  ? false : true;
                }
            },
            classification: {
                valueGetter: (params) => {
                    let value = params.data?.[params.colDef.field];
                    if (value) {
                        return value.charAt(0).toUpperCase() + value.slice(1); // first letter to upper case
                    } else return null;
                },
            },
            openprojectID: {
                cellRenderer: "openprojectWorkPackageSelectorRenderer",
                cellRendererParams: () => {
                    return {
                        entries: openprojectWorkpackagesRef.current
                    };
                },
            }
        };
    }

    // refresh
    async function refresh(agGridParams) {
        props.debugLog(false, ">> REFRESHING START");


        // agGridParams only set, when called from onGridReady()
        const gridApiHere = agGridParams ? agGridParams.api : gridApi;
        const gridColumnApiHere = agGridParams ? agGridParams.columnApi : gridColumnApi
        // remove feedback msg, set loading
        setFeedbackMsg("");
        setFeedbackIsError(false);
        // setLoading(true);

        // save Column state
        const savedState = gridColumnApiHere.getColumnState();

        // Tell AgGrid to reset rowData // important!
        if (gridApiHere) {
            gridApiHere.setRowData(null);
            gridApiHere.showLoadingOverlay(); // trigger "Loading"-state (otherwise would be in "No Rows"-state instead)
        }
        try {
            const timerecords = await getDataFromServer("timerecords"); // get timerecords
            timerecords.forEach(timerecord => {
                timerecord.date = timerecord.from;      // copy date from 'from' to 'date'
            });

            const openprojectWorkPackages = await getDataFromServer("openproject/workpackages");
            const stringifiedOpenprojectWorkPackages = openprojectWorkPackages.map(f => {
                return {
                    id: f.id.toString(), // NOTE: we work with strings for openproject workpackage keys/ids throughout the codebase
                    name: '#' + f.id + ' ' + f.fullpathsubject + ' (' + f.project_fullpathname + ')'
                };
            })
            openprojectWorkpackagesRef.current = stringifiedOpenprojectWorkPackages;

            // set states
            setRowData(timerecords);
            // setLoading(false);

            if (gridApiHere) gridApiHere.setRowData(timerecords);        // Tell AgGrid to set rowData
            // restore saved Column State
            gridColumnApiHere.applyColumnState({ state: savedState });
            
            props.debugLog(false, ">> REFRESHING END");
        } catch (e) {
            setFeedbackMsg(e.toString());
            setFeedbackIsError(true);
            // setLoading(false);
            props.debugLog(true, e);
        }
    };

    function expandCurrentYearAndMonth (params) {
        params.api.forEachNode((node) => {
            // expand current year
            if ( node.key === new Date().getFullYear().toString() ) {
                node.setExpanded(true);
            }
            // expand current month below current year
            if ( node.key === (new Date().getMonth()+1).toString() &&
                 node.parent.key === new Date().getFullYear().toString()
            ) {
                node.setExpanded(true);
            }
            // expand level below Y and M
            if ( node.level >= 2 &&
                 node.parent.key === (new Date().getMonth()+1).toString() &&
                 node.parent.parent.key === new Date().getFullYear().toString()
            ) {
                node.setExpanded(true);
            }
        })
    };

    // ######################################## CRUD OPERATIONS ########################################

    // READ - returns data with given entity name
    async function getDataFromServer(entityName) {
        // GET - /api/timerecords
        try {
            var token = localStorage.getItem("token");
            const entries = await _CRUDController.read(
                props.baseUrl + "/" + entityName,
                token
            );
            return entries;
        } catch (e) {
            props.debugLog(true, e);
        }
    };

    function getISOWeek (dateObject, dowOffset) {
    /*getISOWeek() was developed by Nick Baicoianu at MeanFreePath: http://www.epoch-calendar.com */

        dowOffset = dowOffset ? dowOffset : 0; //default dowOffset to zero
        var newYear = new Date(dateObject.getFullYear(),0,1);
        var day = newYear.getDay() - dowOffset; //the day of week the year begins on
        day = (day >= 0 ? day : day + 7);
        var daynum = Math.floor((dateObject.getTime() - newYear.getTime() - 
        (dateObject.getTimezoneOffset()-newYear.getTimezoneOffset())*60000)/86400000) + 1;
        var weeknum;
        //if the year starts before the middle of a week
        if(day < 4) {
            weeknum = Math.floor((daynum+day-1)/7) + 1;
            if(weeknum > 52) {
                var nYear = new Date(dateObject.getFullYear() + 1,0,1);
                var nday = nYear.getDay() - dowOffset;
                nday = nday >= 0 ? nday : nday + 7;
                /*if the next year starts before the middle of
                   the week, it is week #1 of that year*/
                weeknum = nday < 4 ? 1 : 53;
            }
        }
        else {
            weeknum = Math.floor((daynum+day-1)/7);
        }
        return weeknum;
    };

    function getDayOfWeek (dateObject) {
        var days = ['7-Sunday', '1-Monday', '2-Tuesday', '3-Wednesday', '4-Thursday', '5-Friday', '6-Saturday'];
        return days[dateObject.getDay()]
    };
}
