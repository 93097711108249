import React, { useState, useEffect, useCallback, forwardRef, createRef, useImperativeHandle } from "react";
import { DatePicker } from "antd";
import DateAndTimeParser from "../../../utils/DateAndTimeParser";
import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';

export default forwardRef((props, ref) => {
    const dateAndTimeParser = new DateAndTimeParser();
    const pickerRef = createRef();

    const dateFormat = 'DD.MM.YYYY';
    dayjs.extend(customParseFormat);

    let dateProp = null;
    if(props.colDef.type === "time") dateProp = props.value;
    else if (props.colDef.type === "date") dateProp = dateAndTimeParser.parseDateToDateObj(props.value);
    const [date, setDate] = useState(dateProp);

    // sets focus back on cell to ensure correct navigation
    const setFocusOnCell = useCallback(() => {
        const row = props.api.focusService.focusedCellPosition;
        if(row) props.api.setFocusedCell(row.rowIndex, row.column);  
    }, [props.api])

    useEffect(() => {
        // close DatePicker immediately on change, but keep TimePicker open
        if (props.colDef.type === "date") return () => {
            props.api.stopEditing();
            setFocusOnCell();
        }
    }, [date, props.colDef.type, props.api, setFocusOnCell])

    //console.log(props.value);

    useImperativeHandle(ref, () => {
        return {
            getValue: () => date,
            isPopup: () => true
        };
    });

    return (
        <div ref={pickerRef}>
            {props.colDef.type === "time" ? (
                // NOTE: AntDesign TimePicker (v4) doesn't support closing, when minute has been pressed
                // TimePicker has been moved to own CellEditor
                <></>
            ) : (
                <DatePicker
                    defaultValue={dayjs(date)}
                    onChange={setDate}
                    allowClear={false}
                    open
                    format={dateFormat}
                />
            )}
        </div>
    );
})
